import { Box, VStack } from "@chakra-ui/react";

export const TabPanelOptions: React.FC = () => {

  return (
    <VStack>
      <Box>Options todo</Box>
    </VStack>
  );
};
